@import '~react-image-gallery/styles/css/image-gallery.css';

body {
  font-family: 'Fauna One', serif;
}

/* Desktop viewpoint */
.navigation {
  background-color: rgb(242, 242, 242);
  color: #4b4d4d;
  height: 5vh;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.navigation a:hover {
  color: #3b81f6;
}

.text-heading {
  color: rgb(255, 255, 255);
}

.title-font {
  /* font-family: 'Playfair Display', serif; */
  font-family: 'Fauna One', serif;
}

.background-grey {
  /* main content  */
  color: #f2f2f2;
}

.background-grey-dark {
  /* footer and middle block in page and contact form */
  background-color: #696969;
}

.background-grey-light {
  /* navigation*/
  color: #ffffff;
}
.font-grey {
  /* navigation and body texts */
  color: #4b4d4d;
}
.font-white {
  color: #f8f8f8;
}
.font-black {
  /* heading */
  color: #191919;
}

/* Homepage */

.welcome-cards {
  background-image: url('img/homepage-landing-picture.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 95vh;
}

.small-welcome-cards {
  background-image: url('img/homepage-landing-picture.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 95vh;
}

.contact-image-container {
  height: 35vh;
  background-image: url('img/contactpage-landing-picture.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.about-image-container {
  height: 35vh;
  background-image: url('img/aboutpage-landing-picture.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.photo-slide {
  height: auto;
  width: 100vw;
}

.about-para-container {
  height: 40vh;
  background-color: #696969;
}

.home-separation {
  height: auto;
  background-color: #f2f2f2;
}

.home-separation-two {
  height: auto;
  background-color: #f2f2f2;
}

/* blue bar 2472DA 
  
  font-family: 'Crimson Pro', serif;
  font-family: 'Fauna One', serif;
  font-family: 'Montserrat', sans-serif;
  font-family: 'Playfair Display', serif;
  
  */

.swiper {
  width: 500px;
  height: auto;
}

.photo-swiping-phone {
  width: 390px;
  height: auto;
}

.photo-swiping-monitor {
  width: 600px;
  height: auto;
}

.homepage-text {
  text-shadow: 2px 2px grey;
}

/* .about-image-container-top {
  } */

.location-link {
  color: red;
}

.bold-text {
  font-weight: bold;
}

.mySwiper {
  width: auto;
  height: auto;
}

.swiper-slide {
  width: 80%;
}

.activities-container-one-top {
  background-image: url('img/manaslu.jpg');
  height: 25vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.activities-container-one-bottom {
  height: 30vh;
  font-size: 12px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 600px) {
  .activities-container-one-bottom {
    height: 20vh;
  }
}

.activities-container-two-top {
  background-image: url('img/annapurna.jpg');
  height: 25vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.activities-container-three-top {
  background-image: url('img/tilicho.jpg');
  height: 25vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.activities-container-four-top {
  background-image: url('img/annapurna-basecamp.jpg');
  height: 25vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.contact-container {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}
